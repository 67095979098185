var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("img", {
    staticClass: "zui-logo",
    attrs: { alt: "carlogo", src: _vm.src },
    on: { click: _vm.onClick },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }