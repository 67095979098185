var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("header", { staticClass: "zui-header" }, [
        _c(
          "div",
          { staticClass: "zui-box" },
          [
            _c("logo", { attrs: { type: "carlogo", href: "/" } }),
            _vm._l(_vm.navList, function (item) {
              return _c(
                "zui-button",
                {
                  key: item.router,
                  on: {
                    click: function ($event) {
                      return _vm.navGo(item.router)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t(item.label)) + " ")]
              )
            }),
            _c("div", { staticClass: "zui-right-area" }, [
              _c("table", [
                _c("tr", [
                  _c(
                    "td",
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "bottom",
                            width: "80",
                            trigger: "hover",
                          },
                        },
                        [
                          _c(
                            "ul",
                            { staticStyle: { "text-align": "center" } },
                            [
                              !this.$store.state.ticket
                                ? _c(
                                    "li",
                                    {
                                      staticStyle: {
                                        height: "42px",
                                        "line-height": "42px",
                                        "border-bottom": "1px solid #eee",
                                        cursor: "pointer",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push({
                                            name: "LoginPage",
                                          })
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.$t("d2")) + " ")]
                                  )
                                : _vm._e(),
                              !this.$store.state.ticket
                                ? _c(
                                    "li",
                                    {
                                      staticStyle: {
                                        height: "42px",
                                        "line-height": "42px",
                                        "border-bottom": "1px solid #eee",
                                        cursor: "pointer",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push({
                                            name: "RegisteredPage",
                                          })
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.$t("d3")) + " ")]
                                  )
                                : _vm._e(),
                              this.$store.state.ticket
                                ? _c(
                                    "li",
                                    {
                                      staticStyle: {
                                        height: "42px",
                                        "line-height": "42px",
                                        "border-bottom": "1px solid #eee",
                                        cursor: "pointer",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push({
                                            name: "UserInfo",
                                          })
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.$t("d4")) + " ")]
                                  )
                                : _vm._e(),
                              this.$store.state.ticket
                                ? _c(
                                    "li",
                                    {
                                      staticStyle: {
                                        height: "42px",
                                        "line-height": "42px",
                                        cursor: "pointer",
                                      },
                                      on: { click: _vm.loginOut },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.$t("d5")) + " ")]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c("span", {
                            staticClass: "zui-right-user",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("td", [
                    _c("span", {
                      staticClass: "zui-right-world",
                      on: { click: _vm.changeLocale },
                    }),
                  ]),
                  _c("td", [
                    _c(
                      "span",
                      {
                        staticClass: "zui-right-language-text",
                        on: { click: _vm.changeLocale },
                      },
                      [_vm._v(_vm._s(_vm.$t("language.now_language")))]
                    ),
                  ]),
                  _c("td", [
                    _c("span", {
                      staticClass: "zui-right-language",
                      style: {
                        backgroundImage:
                          _vm.$i18n.locale === "zh"
                            ? "url('../../assets/images/homepage/language_ch.png')"
                            : "url('../../assets/images/homepage/language_en.jpg')",
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticStyle: { clear: "both" } }),
          ],
          2
        ),
      ]),
      _c("transition", { attrs: { name: "slide-fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.maskShow,
                expression: "maskShow",
              },
            ],
            staticClass: "top-nav-mask-box",
            on: {
              click: function ($event) {
                if ($event.target !== $event.currentTarget) return null
                _vm.maskShow = false
                _vm.navShow = false
              },
            },
          },
          [
            _c("transition", { attrs: { name: "el-zoom-in-top" } }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.navShow,
                      expression: "navShow",
                    },
                  ],
                  staticClass: "top-nav-box",
                },
                [
                  _c(
                    "div",
                    { staticClass: "top-nav-left" },
                    _vm._l(_vm.topNavList, function (item, idx) {
                      return _c(
                        "div",
                        {
                          key: item.label,
                          class: { active: _vm.active === idx },
                          on: {
                            mouseenter: function ($event) {
                              _vm.active = idx
                              _vm.subActive = 0
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t(item.label)) + " ")]
                      )
                    }),
                    0
                  ),
                  _c("div", { staticClass: "top-nav-right" }, [
                    _c(
                      "div",
                      {
                        staticClass: "top-list",
                        class: {
                          one: _vm.topNavList[_vm.active].subTitle.length === 1,
                        },
                      },
                      _vm._l(
                        _vm.topNavList[_vm.active].subTitle,
                        function (list, idx) {
                          return _c(
                            "div",
                            {
                              key: list.title,
                              staticClass: "top-list-item",
                              class: { active: _vm.subActive === idx },
                              on: {
                                mouseenter: function ($event) {
                                  _vm.subActive = idx
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t(list.title)) + " ")]
                          )
                        }
                      ),
                      0
                    ),
                    _c("div", { staticClass: "content-box" }, [
                      _vm.topNavList[_vm.active].subTitle[_vm.subActive].has
                        ? _c(
                            "div",
                            { staticClass: "content" },
                            [
                              _c("div", { staticClass: "top-title" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        _vm.topNavList[_vm.active].subTitle[
                                          _vm.subActive
                                        ].topTitle
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("div", { staticClass: "title" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        _vm.topNavList[_vm.active].subTitle[
                                          _vm.subActive
                                        ].contentTitle
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("div", { staticClass: "bottom-title" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        _vm.topNavList[_vm.active].subTitle[
                                          _vm.subActive
                                        ].bottomTitle
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("div", { staticClass: "nav-title" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.topNavList[_vm.active].label ===
                                      "轮辋组件"
                                      ? "性能特征："
                                      : ""
                                  )
                                ),
                              ]),
                              _vm._l(
                                _vm.topNavList[_vm.active].subTitle[
                                  _vm.subActive
                                ].nav,
                                function (item) {
                                  return _c(
                                    "div",
                                    { key: item, staticClass: "nav-item" },
                                    [_vm._v(" " + _vm._s(_vm.$t(item)) + " ")]
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        : _c("div", { staticClass: "null-content" }, [
                            _c("div", [
                              _vm._v(_vm._s(_vm.$t("car_type.default.during"))),
                            ]),
                            _c("img", {
                              attrs: {
                                src: "https://www.achencybe.com/img/pc/footerDetail/5.jpg",
                                alt: "",
                              },
                            }),
                            _c("div", [
                              _vm._v(_vm._s(_vm.$t("car_type.default.follow"))),
                            ]),
                          ]),
                      _c("img", {
                        attrs: {
                          src: _vm.topNavList[_vm.active].subTitle[
                            _vm.subActive
                          ].content,
                        },
                      }),
                    ]),
                    _vm.topNavList[_vm.active].subTitle[_vm.subActive].has
                      ? _c("div", { staticStyle: { "margin-top": "-20px" } }, [
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.toDetail(
                                    _vm.topNavList[_vm.active].subTitle[
                                      _vm.subActive
                                    ].router
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("car_list.learn_more")) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.tobuy(
                                    _vm.topNavList[_vm.active].subTitle[
                                      _vm.subActive
                                    ].buyName
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("car_list.buy_now")) + " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }