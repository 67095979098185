<template>
  <div>
    <header class="zui-header">
      <!--      :style="{borderBottom:hasLine ? '1px solid #dfe1e5' : ''}"-->
      <div class="zui-box">
        <logo type="carlogo" href="/"></logo>
        <!--    <div class="zui-rightcol" @click="openMenu" style="color: #0b8ba5;">目录</div>-->
        <!--    <drop-list :config="configData" ref="droplist"></drop-list>-->
        <zui-button
          v-for="item in navList"
          :key="item.router"
          @click="navGo(item.router)"
          > {{$t(item.label)}}
        </zui-button>
        <div class="zui-right-area">
          <table>
            <tr>
              <td>
                <el-popover placement="bottom" width="80" trigger="hover">
                  <ul style="text-align: center">
                    <li
                      v-if="!this.$store.state.ticket"
                      @click="$router.push({ name: 'LoginPage' })"
                      style="
                        height: 42px;
                        line-height: 42px;
                        border-bottom: 1px solid #eee;
                        cursor: pointer;
                      "
                    >
                      {{$t('d2')}}
                    </li>
                    <li
                      v-if="!this.$store.state.ticket"
                      @click="$router.push({ name: 'RegisteredPage' })"
                      style="
                        height: 42px;
                        line-height: 42px;
                        border-bottom: 1px solid #eee;
                        cursor: pointer;
                      "
                    >
                      {{$t('d3')}}
                    </li>
                    <li
                      v-if="this.$store.state.ticket"
                      @click="$router.push({ name: 'UserInfo' })"
                      style="
                        height: 42px;
                        line-height: 42px;
                        border-bottom: 1px solid #eee;
                        cursor: pointer;
                      "
                    >
                      {{$t('d4')}}
                    </li>
                    <li
                      v-if="this.$store.state.ticket"
                      style="height: 42px; line-height: 42px; cursor: pointer"
                      @click="loginOut"
                    >
                      {{$t('d5')}}
                    </li>
                  </ul>
                  <span class="zui-right-user" slot="reference"></span>
                </el-popover>
              </td>
              <td><span class="zui-right-world" @click="changeLocale"></span></td>
              <td><span class="zui-right-language-text" @click="changeLocale">{{$t('language.now_language')}}</span></td>
              <td><span class="zui-right-language" :style="{backgroundImage: $i18n.locale === 'zh' ? 'url(\'../../assets/images/homepage/language_ch.png\')' : 'url(\'../../assets/images/homepage/language_en.jpg\')'}"></span></td>
            </tr>
          </table>
        </div>
        <div style="clear: both"></div>
      </div>
    </header>
    <transition name="slide-fade">
      <div
        class="top-nav-mask-box"
        v-show="maskShow"
        @click.self="
          maskShow = false;
          navShow = false;
        "
      >
        <transition name="el-zoom-in-top">
          <div class="top-nav-box" v-show="navShow">
            <div class="top-nav-left">
              <div
                :class="{ active: active === idx }"
                v-for="(item, idx) in topNavList"
                :key="item.label"
                @mouseenter="
                  active = idx;
                  subActive = 0;
                "
              >
                {{ $t(item.label) }}
              </div>
            </div>
            <div class="top-nav-right">
              <div class="top-list" :class="{'one':topNavList[active].subTitle.length === 1}">
                <div
                  class="top-list-item"
                  :class="{ active: subActive === idx }"
                  v-for="(list, idx) in topNavList[active].subTitle"
                  :key="list.title"
                  @mouseenter="subActive = idx"
                >
                  {{ $t(list.title) }}
                </div>
              </div>
              <div class="content-box">
                <div
                  class="content"
                  v-if="topNavList[active].subTitle[subActive].has"
                >
                  <div class="top-title">
                    {{ $t(topNavList[active].subTitle[subActive].topTitle) }}
                  </div>
                  <div class="title">
                    {{ $t(topNavList[active].subTitle[subActive].contentTitle) }}
                  </div>
                  <div class="bottom-title">
                    {{ $t(topNavList[active].subTitle[subActive].bottomTitle) }}
                  </div>
                  <div class="nav-title" >{{topNavList[active].label === '轮辋组件' ? '性能特征：' : ''}}</div>
                  <div
                    class="nav-item"
                    v-for="item in topNavList[active].subTitle[subActive].nav"
                    :key="item"
                  >
                    {{ $t(item) }}
                  </div>
                </div>
                <div class="null-content" v-else>
                  <div>{{ $t('car_type.default.during') }}</div>
                  <img src="https://www.achencybe.com/img/pc/footerDetail/5.jpg" alt="" />
                  <div>{{ $t('car_type.default.follow') }}</div>
                </div>
                <img :src="topNavList[active].subTitle[subActive].content" />
              </div>
              <div
                v-if="topNavList[active].subTitle[subActive].has"
                style="margin-top: -20px"
              >
                <button
                  @click="
                    toDetail(topNavList[active].subTitle[subActive].router)
                  "
                >
                  {{ $t('car_list.learn_more') }}
                </button>
                <button
                  @click="tobuy(topNavList[active].subTitle[subActive].buyName)"
                >
                  {{ $t('car_list.buy_now') }}
                </button>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>
<script>
import "./less/header.less";
import ZuiButton from "@/components/Button";
import Logo from "@/components/Logo";
// import Logo from './Logo'
export default {
  components: {
    ZuiButton,
    Logo,
  },
  props: {
    logoType: {
      default: "carlogo",
    },
    hasLine: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      navList: [
        {
          label: 'navbar.models',
          router: "",
        },
        {
          label: 'navbar.business',
          router: "InvestorsFormPage",
        },
        {
          label: 'navbar.sense',
          router: "ValuesPage",
        },
        {
          label: 'navbar.art_gallery',
          router: "ArtGalleryPage",
        },
      ],
      topNavList: [
        {
          label: "car_brand.bmw",
          subTitle: [
            {
              title: "car_type.bmw.the3_show.title",
              content: "https://www.achencybe.com/img/pc/nav/2_03.jpg",
              has: true,
              router: "BMW3ShowPage",
              buyName: "carlistBMW3",
              topTitle: "car_type.bmw.the3_show.top_title",
              contentTitle: "BMW THE 3 (G20/G28/G21)",
              bottomTitle: "car_type.bmw.the3_show.bottom_title",
              nav: [
                "car_type.bmw.the3_show.remark_1",
                "car_type.bmw.the3_show.remark_2",
                "car_type.bmw.the3_show.remark_3",
                "car_type.bmw.the3_show.remark_4",
                "car_type.bmw.the3_show.remark_5",
                "car_type.bmw.the3_show.remark_6"
              ],
            },
            {
              title: "car_type.bmw.M3_show.title",
              content: "https://www.achencybe.com/img/pc/nav/3_03.jpg",
              has: true,
              router: "M3OrM4ShowPage",
              buyName: "carlistM3",
              topTitle: "car_type.bmw.M3_show.top_title",
              contentTitle: "BMW M4/M3(G82/G83/G80/G81)",
              bottomTitle: "car_type.bmw.M3_show.bottom_title",
              nav: [
                "car_type.bmw.M3_show.remark_1",
                "car_type.bmw.M3_show.remark_2",
                "car_type.bmw.M3_show.remark_3",
                "car_type.bmw.M3_show.remark_4",
              ],
            },
            {
              title: "car_type.bmw.the5_show.title",
              content: "https://www.achencybe.com/img/pc/nav/3_04.jpg",
              has: true,
              router: "THE5ShowPage",
              buyName: "carlistTHE5",
              topTitle: "car_type.bmw.the5_show.top_title",
              contentTitle: "BMW  THE 5/M5（G30/G38/F90）",
              bottomTitle: "car_type.bmw.the5_show.bottom_title",
              nav: [
                "car_type.bmw.the5_show.remark_5",
                "car_type.bmw.the5_show.remark_1",
                "car_type.bmw.the5_show.remark_2",
                "car_type.bmw.the5_show.remark_3",
                "car_type.bmw.the5_show.remark_4",
                // "轻量化锻造轮辋",
              ],
            },
            {
              title: "car_type.bmw.the4_show.title",
              content: "https://www.achencybe.com/img/pc/the4/4-0-2.jpg",
              has: true,
              router: "THE4ShowPage",
              buyName: "carlistTHE4",
              topTitle: "car_type.bmw.the4_show.top_title",
              contentTitle: "BMW  THE 4（G22/G26）",
              bottomTitle: "car_type.bmw.the4_show.bottom_title",
              nav: [
                "car_type.bmw.the4_show.remark_1",
                "car_type.bmw.the4_show.remark_2",
                "car_type.bmw.the4_show.remark_3",
              ],
            },
            {
              title: "car_type.bmw.X3_show.title",
              content: "https://www.achencybe.com/img/pc/x3/X3-0-2.jpg",
              has: true,
              router: "X3ShowPage",
              buyName: "carlistX3",
              topTitle: "car_type.bmw.X3_show.top_title",
              contentTitle: "BMW  THE X3 LCI（G08/G01）",
              bottomTitle: "car_type.bmw.X3_show.bottom_title",
              nav: [
                "car_type.bmw.X3_show.remark_1",
                "car_type.bmw.X3_show.remark_2",
                "car_type.bmw.X3_show.remark_3",
              ],
            },
            // {
            //   title: "THE 2",
            //   content: "https://www.achencybe.com/img/pc/nav/0_03.png",
            //   has: false,
            // },
          ],
        },
        {
          label: "car_brand.dodge",
          subTitle: [
            {
              title: "car_type.dodge.challgner_show.title",
              content: "https://www.achencybe.com/img/pc/nav/5_03.jpg",
              has: true,
              router: "ChallengerShowPage",
              buyName: "carlistchallenger",
              topTitle: "car_type.dodge.challgner_show.top_title",
              contentTitle: "car_type.dodge.challgner_show.content_title",
              bottomTitle: "car_type.dodge.challgner_show.bottom_title",
              nav: [
                "car_type.dodge.challgner_show.remark_1",
                "car_type.dodge.challgner_show.remark_2",
                "car_type.dodge.challgner_show.remark_3",
                "car_type.dodge.challgner_show.remark_4",
                "car_type.dodge.challgner_show.remark_5",
              ],
            },
            {
              title: "Charger SRT",
              content: "https://www.achencybe.com/img/pc/nav/0_03.png",
              has: false,
            },
          ],
        },
        {
          label: "car_brand.benz",
          subTitle: [
            {
              title: "C-class",
              content: "https://www.achencybe.com/img/pc/nav/0_03.png",
              has: false,
            },
            {
              title: "A45 AMG",
              content: "https://www.achencybe.com/img/pc/nav/0_03.png",
              has: false,
            },
            {
              title: "G63 AMG",
              content: "https://www.achencybe.com/img/pc/nav/0_03.png",
              has: false,
            },
            {
              title: "AMG GT",
              content: "https://www.achencybe.com/img/pc/nav/0_03.png",
              has: false,
            },
          ],
        },
        {
          label: "car_brand.audi",
          subTitle: [
            {
              title: "S4/A4",
              content: "https://www.achencybe.com/img/pc/nav/0_03.png",
              has: false,
            },
            {
              title: "RS 4",
              content: "https://www.achencybe.com/img/pc/nav/0_03.png",
              has: false,
            },
            {
              title: "A6",
              content: "https://www.achencybe.com/img/pc/nav/0_03.png",
              has: false,
            },
            {
              title: "RS 6",
              content: "https://www.achencybe.com/img/pc/nav/0_03.png",
              has: false,
            },
          ],
        },
        {
          label: "car_brand.volkswagen",
          subTitle: [
            {
              title: "Golf",
              content: "https://www.achencybe.com/img/pc/nav/0_03.png",
              has: false,
            },
          ],
        },
        {
          label: "car_brand.porsche",
          subTitle: [
            {
              title: "911(₉₉₂)",
              content: "https://www.achencybe.com/img/pc/nav/0_03.png",
              has: false,
            },
            {
              title: "718",
              content: "https://www.achencybe.com/img/pc/nav/0_03.png",
              has: false,
            },
            {
              title: "Macan",
              content: "https://www.achencybe.com/img/pc/nav/0_03.png",
              has: false,
            },
            {
              title: "Panamera",
              content: "https://www.achencybe.com/img/pc/nav/0_03.png",
              has: false,
            },
          ],
        },
        {
          label: "car_brand.rim",
          subTitle: [
            {
              title: "car_type.rim.rim_show.title",
              content: "https://www.achencybe.com/img/pc/nav/6_01.jpg",
              has: true,
              router: "RimShowPage",
              buyName: "rim",
              topTitle: "car_type.rim.rim_show.top_title",
              contentTitle: "car_type.rim.rim_show.content_title",
              bottomTitle: "",
              nav: [
                "car_type.rim.rim_show.remark_1",
                "car_type.rim.rim_show.remark_2",
                "car_type.rim.rim_show.remark_3",
                "car_type.rim.rim_show.remark_4",
                "car_type.rim.rim_show.remark_5",
              ],
            },
          ],
        },
      ],
      active: 0,
      subActive: 0,
      maskShow: false,
      navShow: false,
    };
  },
  // components: {Logo},
  methods: {
    changeLocale(){
      let locale = this.$i18n.locale
      locale === 'zh' ? this.$i18n.locale = 'en' : this.$i18n.locale = 'zh'
      this.$store.commit("setLanguage", this.$i18n.locale);
    },
    navGo(router) {
      if (!router) {
        this.maskShow = !this.maskShow;
        this.navShow = !this.navShow;
      } else {
        if (this.$route.name !== router) {
          this.$router.push({ name: router });
        }
      }
    },
    toDetail(router) {
      this.$router.push({ name: router });
    },
    tobuy: function (name) {
      if(name == 'Development'){
        this.$router.push({ name: name });
        return
      }
      if(name === 'carlistX3'){
        this.$router.push({
          path: "/pages/public/TemplateOrderPage",
          query: { cartype: name },
        });
        return
      }
      // return
      this.$router.push({
        path: "/pages/public/OrderPage",
        query: { cartype: name },
      });
    },
    loginOut() {
      this.$store.commit("loginOut");
      this.$router.push({ name: "LoginPage" });
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.2s ease;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}

.top-nav-mask-box {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(15px);
  z-index: 998;
  transition: 0.5s;

  .top-nav-box {
    box-sizing: border-box;
    width: 1330px;
    height: 528px;
    position: absolute;
    top: 100px;
    left: 113px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 23px 0;

    .top-nav-left {
      width: 380px;
      font-size: 18px;
      padding: 28px 64px 28px;
      text-align: left;
      cursor: pointer;
      color: #919191;

      .active {
        font-weight: bold;
        color: #000;
      }

      div + div {
        margin-top: 40px;
      }
    }

    .top-nav-right {
      text-align: left;
      border-left: 2px solid #808080;
      padding: 0 62px;

      .top-list {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-bottom: 1px solid #808080;
        color: #919191;
        &.one{
          justify-content: flex-start;
          padding-left:30px;
        }
        .top-list-item.active {
          font-weight: bold;
          color: #000;
        }
      }
      .content-box {
        padding-left: 30px;
        width: 852px;
        position: relative;
        img {
          width: 852px;
        }
        .content {
          color: #323232;
          position: absolute;
          padding-top: 30px;
          .top-title {
          }
          .title {
            font-size: 22px;
            font-weight: bold;
          }
          .bottom-title {
            margin-top: 6px;
          }
          .nav-title {
            padding: 20px 0;
            font-size: 14px;
          }
          .nav-item {
            font-size: 14px;
            line-height: 1.6;
          }
        }
        .null-content {
          color: #323232;
          position: absolute;
          padding-top: 60px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            width: 160px;
            margin: 10px 0;
          }
        }
      }

      button {
        width: 208px;
        height: 42px;
        background-color: #fff;
        border: 1px solid #3d7bfe;
        font-size: 18px;
        cursor: pointer;
        margin-top: 18px;
        color: #3d7bfe;
      }

      button + button {
        background-color: #3d7bfe;
        color: #fff;
        margin-left: 25px;
      }
    }
  }
}
</style>
