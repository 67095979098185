<template>
 <img alt="carlogo" class="zui-logo" :src="src" @click="onClick"/>
</template>
<script>
  import './less/logo.less'
  export default {
    name: 'Logo',
    props: {
      type: {
        default: 'carlogo'
      },
      href: {
        default: '/'
      }
    },
    computed: {
      src: function () {
        if (this.type === 'carlogo') {
          return 'https://www.achencybe.com/img/pc/logo_header.png'
        }else{
          return ""
        }
      }
    },
    methods: {
      onClick: function (e) {
        if(this.type==="carlogo"){
           location.assign(this.href)
        }
      }
    }
  }
</script>